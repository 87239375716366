.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #003458;
    overflow: hidden;
}

.not-found-container {
    max-width: 400px;
    text-align: center;
}
  
.not-found-title {
    font-size: 72px;
    color: #36B09A;
    margin: 0;
    padding: 0;
}
  
.not-found-text {
    font-size: 24px;
    color: whitesmoke;
}
  
.animated-background {
    width: 200px;
    height: 200px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
}
  
.particles {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    perspective: 800px;
  }
  
.particle {
    width: 12px;
    height: 12px;
    background-color: #36B09A;
    position: absolute;
    border-radius: 50%;
    opacity: 0.8;
    animation: explode 2s infinite;
}
  
.particle:nth-child(1) {
    top: 25%;
    left: 25%;
    animation-delay: 0s;
}
  
.particle:nth-child(2) {
    top: 25%;
    left: 50%;
    animation-delay: 0.2s;
}
  
.particle:nth-child(3) {
    top: 25%;
    left: 75%;
    animation-delay: 0.4s;
}
  
.particle:nth-child(4) {
    top: 50%;
    left: 25%;
    animation-delay: 0.6s;
}
  
.particle:nth-child(5) {
    top: 50%;
    left: 50%;
    animation-delay: 0.8s;
}
  
.particle:nth-child(6) {
    top: 50%;
    left: 75%;
    animation-delay: 1s;
}
  
.particle:nth-child(7) {
    top: 75%;
    left: 25%;
    animation-delay: 1.2s;
}
  
.particle:nth-child(8) {
    top: 75%;
    left: 50%;
    animation-delay: 1.4s;
}
  
.particle:nth-child(9) {
    top: 75%;
    left: 75%;
    animation-delay: 1.6s;
}
  
@keyframes explode {
    0% {
      transform: translate3d(0, 0, 0) rotateZ(0);
      opacity: 0.8;
    }
    50% {
      transform: translate3d(0, 100px, 0) rotateZ(720deg);
      opacity: 0.4;
    }
    100% {
      transform: translate3d(0, 200px, 0) rotateZ(1440deg);
      opacity: 0;
    }
}

  