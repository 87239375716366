button {
    font: inherit;
    border: 1px solid #4f005f;
    background: RGB(64, 224, 208);
    color: white;
    padding: 0.25rem 1rem;
    cursor: pointer;
    border-radius: 12%;
}

button:hover,
button:active {
    background: RGB(64, 224, 208);
    border-color: RGB(64, 224, 208);
}

button:focus {
    outline: none;
}