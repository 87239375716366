.photocard {
    position: fixed;
    top: 45vh;
    left: 40%;
    height: 20%;
    width: 20%;
    z-index: 100;
    overflow: hidden;
}

.photocard button{
    height: 15%;
    position: absolute;
        bottom: 0.5%;
        right: 40%;
}