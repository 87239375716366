.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: #000000bf;
}

.modal {
    position: fixed;
    top: 30vh;
    left: 10%;
    height: 50%;
    width: 80%;
    z-index: 100;
    overflow: hidden;
    height: 800;
    width: 500;
}

.modal button {
    /* position: absolute;
    bottom: 5%;
    right: 5%;
    border: 0; */
}

.modal header button {
    position: absolute;
    bottom: 88%;
    right: 5%;
    outline: 0ch;
    border: 0;
    margin-top: 20px;
}


.header {
    background: RGB(64, 224, 208);
    padding: 1rem;
    text-align: center;
}

.header h2 {
    margin: 0;
    color: white;
    font-family: '';
}

.content {
    padding: 1rem;
}